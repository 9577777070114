export default function StageMagicContent() {
  return (
    <div className="content">
      Experience the extraordinary with my captivating stage magic performances.
      Ideal for venues with a stage and a seated audience, my show combines
      mesmerizing sleight of hand demonstrations, astonishing blindfold acts,
      and an enchanting fusion of magic and mentalism. Prepare to be entertained
      and immersed in a world where impossible moments come to life.
    </div>
  );
}

import './App.css';

import React, { useState } from 'react';

import AboutContent from './AboutContent';
import Card from './Card';
import closeup from './closeup.png';
import closeup2 from './closeup2.png';
import CloseUpContent from './CloseUpContent';
import GetInTouch from './GetInTouch';
import HeaderContent from './HeaderContent';
import Logo from './Logo';
import ProfilePic from './ProfilePic';
import stage from './stage.jpg';
import StageMagicContent from './StageMagicContent';

function App() {
  const [magicMode, setMagicMode] = useState<Boolean>(false);

  if (!magicMode) {
    return (
      <div className="App">
        <Logo />
        <HeaderContent />
        <center>
          <img src={closeup} className="firstPic" alt="close up magic" />
          <div className="performances">Performances</div>
          <div>
            <h3>CLOSE UP MAGIC</h3>
            <img src={closeup2} className="secondPic" alt="close up magic" />
            <CloseUpContent />
          </div>
          <div className="stageMagicWrapper">
            <h3>STAGE MAGIC</h3>
            <img src={stage} className="thirdPic" alt="stage magic" />
            <StageMagicContent />
          </div>
          <div className="aboutme">About Me</div>
          <ProfilePic onClick={() => setMagicMode(!magicMode)} />
          <AboutContent />
          <GetInTouch />
        </center>
      </div>
    );
  }

  return (
    <div className="CardApp">
      <Card label="A">
        <Logo />
      </Card>
      <Card label="2">
        <HeaderContent />
      </Card>
      <Card label="3">
        <h1>Performances</h1>
        <img src={closeup} className="firstPic" alt="close up magic" />
      </Card>
      <Card label="4">
        <h2>CLOSE UP MAGIC</h2>
        <img src={closeup2} className="secondPic" alt="close up magic" />
        <CloseUpContent />
      </Card>
      <Card label="5">
        <h2>STAGE MAGIC</h2>
        <img src={stage} className="thirdPic" alt="stage magic" />
        <StageMagicContent />
      </Card>
      <Card label="K">
        <ProfilePic onClick={() => setMagicMode(!magicMode)} />
      </Card>
      <Card label="7">
        <div className="aboutme">About Me</div>
        <AboutContent />
      </Card>
      <Card label="8">
        <GetInTouch />
      </Card>
    </div>
  );
}

export default App;

export default function GetInTouch() {
  return (
    <div className="contactWrapper">
      <h3>GET IN TOUCH</h3>
      <div>
        <b>Email: </b>
        <a href="mailto:info@luisshrestha.com">info@luisshrestha.com</a>
      </div>
      <div>
        <b>Phone: </b>
        <a href="tel:986-937-5737">+977 (986) 937-5735</a>
      </div>
      <div>
        <b>IG: </b>
        <a href="https://www.instagram.com/magicbyluis/">@magicbyluis</a>
      </div>
    </div>
  );
}

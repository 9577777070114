export default function CloseUpContent() {
  return (
    <div className="content">
      Prepare to be enchanted by my close-up magic, designed for corporate
      events, restaurants, weddings, and occasions where people come together to
      celebrate, network, or socialize. As I effortlessly mingle with guests, I
      deliver mind-blowing magic that will leave them spellbound and create
      memories that last a lifetime.
    </div>
  );
}

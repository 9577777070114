export default function AboutContent() {
  return (
    <div className="aboutContent">
      <p>
        My journey started by me writing things that could be performed to an
        audience. In that journey, I stumbled upon magic which I instantly fell
        in love with and knew it is something I wanted to do. Since then magic
        is in my head 24/7 and the deck of cards never leaves my hand. 
      </p>
      <p>
        Being called the finest sleight of hand artist of Nepal and given the
        responsibility to run Nepali Brotherhood of magician(which is the only
        organization dedicated to magician in Nepal) so quickly since I started
        magic, it gets me excited and also makes me feel responsible for the
        future of magic and live shows in Nepal.
      </p>
    </div>
  );
}

import profile from './profile.png';

type Props = { onClick: () => void };

export default function ProfilePic({ onClick }: Props) {
  return (
    <img
      src={profile}
      onClick={onClick}
      className="profilePic"
      alt="profile picture"
    />
  );
}
